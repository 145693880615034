/***************************************************
  Imports
***************************************************/

import React, { useState, useEffect } from "react"

import { useRecoilState } from "recoil"
import { firstTimeAction } from "../state"

import { Link } from "gatsby"

import IconCaret from "../images/icon-caret.svg"

/***************************************************
  Component
***************************************************/

export default ({ title, active, options }) => {
  const [firstTime, setFirstTime] = useRecoilState(
    firstTimeAction("dropDownMenu")
  )

  const [isOpen, setIsOpen] = useState(firstTime)

  useEffect(() => {
    if (firstTime) {
      setTimeout(() => {
        setFirstTime(false)
        setIsOpen(false)
      }, 500)
    }
  }, [])

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  let currentIndex = 0
  if (active) {
    currentIndex = options.findIndex(x => x.title == active)
  }

  return (
    <>
      <h4 className="drop-down-menu-title">{title}</h4>
      <div className={`drop-down-menu ${isOpen ? "open" : undefined}`}>
        <div onClick={toggleOpen}>
          <div>
            <img class="caret" src={IconCaret} />
            <h1 class="selected-number">
              {(currentIndex + 1).toString().padStart(2, "0")}
            </h1>
          </div>
          <h1>{options[currentIndex].title}</h1>
        </div>
        <div style={{ maxHeight: options.length * 5 + "rem" }}>
          {options.map((option, index) => {
            return (
              <Link
                key={option.title}
                className={index === currentIndex && "active"}
                to={option.to}
              >
                <h4 class="option-number">
                  {(index + 1).toString().padStart(2, "0")}
                </h4>
                <h4>{option.title}</h4>
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}
